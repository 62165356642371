/* eslint-disable no-console */
import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import ConnectTo from '../../store/connect';

const EXACT_SPOTTER_SETTINGS_BUCKET_DOCUMENT_ID = 'ext:exactSpotter:settings';

const Home = ({ appInfo, settings, dispatch }) => {
    useEffect(() => {
        window.location.href = 'https://plugins-v2.whitewall.dev/plugins/exactspotter.html';
    }, []);

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            Carregando...
        </div>
    );
};

Home.propTypes = {
    appInfo: PropTypes.object,
    settings: PropTypes.object,
    dispatch: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        appInfo: state.application.appInfo,
        language: state.common.language,
        buckets: state.application.buckets,
        settings: state.application.buckets[
            EXACT_SPOTTER_SETTINGS_BUCKET_DOCUMENT_ID
        ] || {
            exactToken: '',
            publicKey: ''
        },
        ...props
    };
};

export default ConnectTo(mapStateToProps)(Home);
